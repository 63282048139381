import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Input from "../../atoms/Input/Input";

import "./BestBefore.less";

const BestBeforeTool = props => {
  const [bestBefore, setBestBefore] = React.useState("");
  const [harvest, setHarvest] = React.useState(null);

  const onChange = e => {
    setBestBefore(e.target.value);
    const selected = props.harvests.find(x => x.bestBefore === e.target.value);
    if (selected) {
      setHarvest(selected);
    } else {
      setHarvest(null);
    }
  };

  return (
    <div className="best-before text-max-line">
      {/* <pre>{JSON.stringify(props.harvests, null, 4)}</pre> */}
      <div className="best-before__date">
        <Input
          name="bestBefore"
          value={bestBefore}
          onChange={e => onChange(e)}
          placeholder="Datum (TT.MM.JJJJ)"
          outline={true}
          maxLength={10}
        />
      </div>
      {!harvest && bestBefore.length === 10 && (
        <div className="best-before__result best-before__result--error">
          <h2>Hoppla...</h2>
          <p>
            Für dieses Datum habe ich keine passende Ernte gefunden. Hast Du
            Dich vielleicht vertippt?
          </p>
        </div>
      )}
      {harvest && (
        <div className="best-before__result">
          <h2>Sorte: {harvest.kind}</h2>
          <p>
            Dieses Glas Honig enthält hauptsächlich diese Sorte, wurde am{" "}
            {harvest.harvestDate} geerntet und ist <em>mindestens</em> bis zum{" "}
            {harvest.bestBefore} haltbar.
          </p>
          {harvest.html && (
            <div dangerouslySetInnerHTML={{ __html: harvest.html }} />
          )}
        </div>
      )}
    </div>
  );
};

BestBeforeTool.protoTypes = {
  harvests: PropTypes.shape({
    harvestDate: PropTypes.string.isRequired,
    bestBefore: PropTypes.string.isRequired,
    kind: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired
  })
};

const BestBefore = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          sourceInstanceName: { eq: "charges" }
          extension: { eq: "md" }
        }
      ) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                harvestDate
                bestBefore
                kind
              }
              html
            }
          }
        }
      }
    }
  `);
  const options = { month: "2-digit", day: "2-digit", year: "numeric" };
  const harvets = data.allFile.edges.map(harvest => ({
    ...harvest.node.childMarkdownRemark.frontmatter,
    harvestDate: new Intl.DateTimeFormat("de-DE", options).format(
      new Date(harvest.node.childMarkdownRemark.frontmatter.harvestDate)
    ),
    bestBefore: new Intl.DateTimeFormat("de-DE", options).format(
      new Date(harvest.node.childMarkdownRemark.frontmatter.bestBefore)
    ),
    html: harvest.node.childMarkdownRemark.html
  }));
  return <BestBeforeTool harvests={harvets} />;
};

export default BestBefore;
