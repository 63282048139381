import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

const ImageComponent = ({ src, title, className }) => {
  const classNames = ["image"];
  if (className) {
    classNames.push(className);
  }

  return (
    <img className={classNames.join(" ")} src={src} alt={title} title={title} />
  );
};

ImageComponent.protoTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};

const Image = ({ src, title, poster, className }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { ext: { regex: "/(jpg|png)/" } }) {
            edges {
              node {
                name
                extension
                relativePath
                publicURL
              }
            }
          }
        }
      `}
      render={result => {
        const image =
          result.allFile.edges.find(
            ({ node }) => node.relativePath === src
          ).node || null;
        if (image) {
          return (
            <ImageComponent
              src={image.publicURL}
              title={title}
              className={className || undefined}
            />
          );
        } else {
          return <div>Image not found</div>;
        }
      }}
    />
  );
};

Image.protoTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default Image;
