import React from "react";

import Layout from "../components/organisms/Layout/Layout";
import PageWidth from "../components/organisms/PageWidth/PageWidth";
import SEO from "../components/atoms/SEO/SEO";
import Section from "../components/organisms/Section/Section";
import FAQ from "../components/templates/FAQ/FAQ";
import Image from "../components/atoms/Image/Image";
import BestBefore from "../components/organisms/BestBefore/BestBefore";
import OgImage from "../images/og/mhd.jpg";
import CartPage from "../components/organisms/Shop/CartPage/CartPage";

const MHD = () => (
  <>
  <CartPage />
  <Layout>
    <SEO
      title="Honigblüte erkennen"
      description="Anhand des MDHs kann ich Dir sagen, welche Sorte Honig Du hast."
      image={OgImage}
    />
    <Section>
      <PageWidth>
        <h1>Honigblüte erkennen</h1>
        <p className="text-max-line">
          Hier kannst Du das Mindest&shy;haltbarkeits&shy;datum Deines Honigs
          eingeben und erfährst um welche Sorte es sich handelt. Du findest
          dieses Datum handschriftlich auf Deinem Glas:
        </p>
        <BestBefore />
      </PageWidth>
    </Section>
    <Section>
      <PageWidth>
        <FAQ
          title={
            <>
              Häufige
              <br />
              Fragen:
            </>
          }
          questions={[
            {
              title: "Ist die angegebene Sorte ausschließlich im Glas?",
              text:
                "Nein. In Honig ist fast niemals nur 100% eine Sorte enthalten."
            },
            {
              title:
                "Mein Honig hat oben teilweise weißen Schaum. Ist er schlecht?",
              text: (
                <>
                  Nein. Frischer, flüssiger Honig bildet manchmal Honigschaum
                  aus. Der ist essbar. Was ich beim Abfüllen sehe, nehme ich ab.
                  Kleine Mengen landen aber manchmal trotzdem im Glas. Das
                  mindert <strong>nicht</strong> die Qualität des Honigs.
                  <br />
                  <br />
                  <Image
                    src="Honigschaum.jpg"
                    title="Honigschaum auf einem frischen Honig"
                  />
                </>
              )
            }
          ]}
        />
      </PageWidth>
    </Section>
  </Layout>
  </>
);

export default MHD;
